.address-preview {
  width: 100%;
  height: 320px;
  display: flex;
  justify-content: center;
  position: relative;

  .address-preview-map {
    width: 320px;
    height: 320px;
  }

  &.changed {
    .address-preview-map {
      opacity: 0.3;
    }
    .address-preview-changed {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: 1em;
    }
  }
}
